
import genericHelpers from '../_utils/generic-helpers';

// -----------------------------------------------------------
//  ZipCodes
// -----------------------------------------------------------
const hasLocalStorage = () => ('localStorage' in window);

function load() {
  const storedZipCode = () => {
    if (hasLocalStorage()) {
      return window.localStorage.getItem('zipCode');
    }
  };
  const orderZipCode = () => {
    if (window.App) {
      return window.App.order.postal_code;
    }
  };

  return storedZipCode() || orderZipCode() || '';
}

function fromLatLong(url, lat, lng, callback) {
  genericHelpers.promisifiedXHR(url, 'GET', { lat, lng })
    .then(callback)
    .catch((error) => console.error(error));
}

function save(zipCode) {
  if (hasLocalStorage() && zipCode) {
    window.localStorage.setItem('zipCode', zipCode);
  }
}

function persist(url, zipCode) {
  if (window.App
        && window.App.order.logged_in()
        && url
        && zipCode) {
    genericHelpers.promisifiedXHR(url, 'POST', { value: zipCode })
      .catch(() => {});
  }
}

export default {
  load, fromLatLong, save, persist,
};
